import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card mw-800px w-100"
}
const _hoisted_2 = { class: "mb-8 text-center" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "mt-4 d-flex justify-content-center flex-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.gdprDoc)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.gdprDoc.title), 1),
        _createElementVNode("p", _hoisted_3, " Version " + _toDisplayString(_ctx.gdprDoc.version) + " en date du " + _toDisplayString(_ctx.parseDate(_ctx.gdprDoc.date_created)), 1),
        _createElementVNode("p", {
          align: "justify",
          ref: "htmlContent",
          innerHTML: _ctx.gdprDoc.content
        }, null, 8, _hoisted_4),
        (_ctx.gdprDoc.doc_type === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'gdpr-charter' }))),
                class: "btn btn-primary m-1"
              }, " Charte de Protection des Données Personelles "),
              _createElementVNode("a", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'cookie-policy' }))),
                class: "btn btn-primary m-1"
              }, " Politique de Cookies et Traçeurs "),
              _createElementVNode("a", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'general-conditions' }))),
                class: "btn btn-primary m-1"
              }, " Conditions Générales d'Utilisation ")
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}