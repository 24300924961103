
import {
  notify,
  parseDate,
  openLinkAgriBest,
} from "@/core/helpers/globalJaya.ts";
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "gdpr_doc_viewer",
  components: {},
  props: {
    docType: String,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const htmlContent = ref();

    const doc_type = computed(() => {
      const name = props.docType
        ? props.docType
        : router.currentRoute.value.name;
      return store.getters.getGdprDocTypes.find((type) => type.name === name)
        .choice;
    });

    const gdprDoc = computed(() => {
      return store.getters.getGdprLastDocs.find(
        (doc) => doc.doc_type === doc_type.value
      );
    });

    const addClickEvent = () => {
      const anchors = htmlContent.value.getElementsByTagName("a");
      for (let i = 0; i < anchors.length; i++) {
        const href = anchors[i].getAttribute("href");
        anchors[i].removeAttribute("href");
        anchors[i].classList.add("text-primary", "cursor-pointer");
        anchors[i].addEventListener("click", () => {
          openLinkAgriBest(href);
        });
      }
    };

    const removeClickEvent = () => {
      const anchors = htmlContent.value.getElementsByTagName("a");
      for (let i = 0; i < anchors.length; i++) {
        const href = anchors[i].getAttribute("href");
        anchors[i].removeEventListener("click", () => {
          openLinkAgriBest(href);
        });
      }
    };

    onMounted(() => {
      store
        .dispatch(Actions.FETCH_GDPR_LAST_DOCS)
        .then(() => {
          addClickEvent();
        })
        .catch(() => {
          router.push({ name: "homepage" });
          notify({
            text: "Une erreur est survenue. Veuillez réessayer ultérieurement",
            color: "error",
          });
        });
    });

    onBeforeUnmount(() => {
      removeClickEvent();
    });

    return {
      gdprDoc,
      htmlContent,
      parseDate: parseDate,
    };
  },
});
