
import { defineComponent, ref, onMounted, nextTick, computed } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import { Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import GdprDocViewer from "@/views/GdprDocViewer.vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";

export default defineComponent({
  name: "sign-up",
  components: {
    Form,
    GdprDocViewer,
    CardTitleJaya,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const _stepperObj = ref<StepperComponent | null>(null);
    const gdprSignatureStepper = ref<HTMLElement | null>(null);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const currentStepIndex = ref(0);

    let docAccepted = ref<boolean>(false);

    const gdprDocTypes = computed(() => {
      return store.getters.getGdprDocTypes;
    });

    const gdprLastDocsNotSigned = computed(() => {
      return store.getters.getGdprLastDocsNotSigned;
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }
      return _stepperObj.value.totatStepsNumber;
    });

    const stepValid = computed(() => {
      if (_stepperObj.value) {
        if (currentStepIndex.value === 0) {
          return true;
        }
        return docAccepted.value;
      }
      return false;
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }
      docAccepted.value = false;
      currentStepIndex.value--;
      _stepperObj.value.goPrev();
    };

    const nextStep = () => {
      if (!_stepperObj.value) {
        return;
      }
      window.scrollTo(0, 0);
      docAccepted.value = false;
      currentStepIndex.value++;
      _stepperObj.value.goNext();
    };

    const onSubmitSign = () => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        submitButton.value?.setAttribute("data-kt-indicator", "on");
      }

      Promise.all(
        gdprLastDocsNotSigned.value.map((e) => {
          return store.dispatch(Actions.CREATE_GDPR_SIGNATURE, {
            user: store.getters.currentUser.id,
            gdpr_doc: e.id,
            date_created: new Date().toISOString(),
          });
        })
      ).then(() => {
        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
        router.push({ name: "useraccount" });
      });
    };

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        gdprSignatureStepper.value as HTMLElement
      );
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });

    return {
      gdprSignatureStepper,
      submitButton,
      currentStepIndex,
      docAccepted,
      totalSteps,
      stepValid,
      previousStep,
      nextStep,
      onSubmitSign,
      gdprDocTypes,
      gdprLastDocsNotSigned,
    };
  },
});
